import React, { useState } from "react";
import Stars from "./Stars";



function Faq() {

    const ReadMore = ({ children }) => {
        const text = children;
        const [isReadMore, setIsReadMore] = useState(true);
        const toggleReadMore = () => {
            setIsReadMore(!isReadMore);
        };
        return (
            <p className="text">
                {isReadMore ? text.slice(0, 100) : text}
                <span onClick={toggleReadMore} className="read-or-hide text-gray-400">
                    {isReadMore ? "...read more" : " show less"}
                </span>
            </p>
        );
    };
    const moverReview = [
        {
            name: "Michelle",
            date: "March 11, 2022",
            // tittle: "Movers on the way is the best!",
            description: "Very concerned to get the job done in a timely manner, fighting days of rain.",
            rate: 5,
            red: "Networx",
            enlace: 'https://porch.com/elgin-il/insulation-contractors/jaramillo-installers/pp#reviews',
            ima: 'https://firebasestorage.googleapis.com/v0/b/clientesimages.appspot.com/o/directorios%2Fnetworx.png?alt=media&token=5da761fe-c2f6-4f2e-92f2-39c55d96e360',
        },
        {
            name: "Cynthia E",
            date: "March 28, 2019",
            // tittle: "Movers on the way is the best!",
            description: "The Argueta Construction Co. Sent their A team everyone was polite respectful and exceptionally professional. The did everything I asked for and quality work , reasonable price.I am extremely happy with what they did for me and I highly recommend them I will definitely be a repeat customer.",
            rate: 5,
            red: "Networx",
            enlace: 'https://porch.com/elgin-il/insulation-contractors/jaramillo-installers/pp#reviews',
            ima: 'https://firebasestorage.googleapis.com/v0/b/clientesimages.appspot.com/o/directorios%2Fnetworx.png?alt=media&token=5da761fe-c2f6-4f2e-92f2-39c55d96e360',

        },
        {
            name: "Isaac R",
            date: "August 29, 2017",
            // tittle: "Movers on the way is the best!",
            description: " I have just had the best experience of watching two of the best contract crew members of Argueta Construction LLC. I had over 255 SQ feet of concrete laid by Brayan and his helper. These two guys were awesome working together, they were super friendly, and they kelp me up to date in what they were doing, and how they would do it. I went online Saturday looking for someone to lay concrete, thirty minutes later I get a call from Lydia, a woman who made me feel, as if I had been knowing her for a long time. Her friendly, sweet and charming voice, convinced me that these are the kind of people I want to have come out to work for me. I’ve had many contractors in the past come out to my place to do work, but they did not come close to giving me the kind of service I’ve gotten in these last three days from Brayan and his team. Thank you Brayan, your work went beyond my expectations; my wife and I are very pleased. Anyone looking for a contractor that is diverse in several levels of contract working; Brayan Construction LLC is among the best if not the best to call to get what you want done right.",
            rate: 5,
            enlace: 'https://porch.com/elgin-il/insulation-contractors/jaramillo-installers/pp#reviews',
            ima: 'https://firebasestorage.googleapis.com/v0/b/clientesimages.appspot.com/o/directorios%2Fnetworx.png?alt=media&token=5da761fe-c2f6-4f2e-92f2-39c55d96e360',
            red: "Networx",
        },

        {
            name: "quanliao",
            date: "April 12, 2022",
            // tittle: "Movers on the way is the best!",
            description: "Family business, for my project, the employer and the employees are family, since the relationship, they easily communicate and work with me. During the whole process I always put out some request base one the job conditions, they can invoiced it no problem base on the contract but they try their best to reach my extra request (for free or charged less fee ). My major project is driveway but they also do the fence job and fence gate. It helped me save a lot time to find another contractor. Typically I need to say thank s to Richie , he is very nice guy, very nice. His major job is concrete ,fence installation and repair, but if he also partner with some business can do roof repair and replace even car theater systems and repair, lawn water system, garage electrical and plumbing.so if who have these services, I recommend contact with Richie.",
            rate: 5,
            red: "Networx",
            enlace: 'https://porch.com/elgin-il/insulation-contractors/jaramillo-installers/pp#reviews',
            ima: 'https://firebasestorage.googleapis.com/v0/b/clientesimages.appspot.com/o/directorios%2Fnetworx.png?alt=media&token=5da761fe-c2f6-4f2e-92f2-39c55d96e360',
        },

        {
            name: "Earl",
            date: "August 11, 2022",
            // tittle: "Movers on the way is the best!",
            description: "Great job done. This is a happy crew and we enjoyed watching them work. They were very efficient and careful with their work. We highly recommend them.",
            rate: 5,
            red: "Networx",
            enlace: 'https://porch.com/elgin-il/insulation-contractors/jaramillo-installers/pp#reviews',
            ima: 'https://firebasestorage.googleapis.com/v0/b/clientesimages.appspot.com/o/directorios%2Fnetworx.png?alt=media&token=5da761fe-c2f6-4f2e-92f2-39c55d96e360',
        },

        {
            name: "Patrick",
            date: "Sept 11, 2022",
            // tittle: "Movers on the way is the best!",
            description: "Árgueta Construction gets my highest recommendation! Friendly workers and a very fair price, plus job completed in a timely fashion. Brayan and Lydia were quick to respond to any any questions I had as well. If I need more concrete work, I’ll call them first! I suggest you do the same!",
            rate: 5,
            red: "Networx",
            enlace: 'https://porch.com/elgin-il/insulation-contractors/jaramillo-installers/pp#reviews',
            ima: 'https://firebasestorage.googleapis.com/v0/b/clientesimages.appspot.com/o/directorios%2Fnetworx.png?alt=media&token=5da761fe-c2f6-4f2e-92f2-39c55d96e360',
        },
        
    ];


    return (
        <section className="w-4/5 h-auto my-10 mx-auto gap-5 grid md:grid-cols-3 grid-cols-1">
            {
                moverReview.map((item, index) => {
                    return (
                        <article key={index} className="h-auto p-2 flex flex-col rounded-md bg-gray-100 w-auto">
                            <div className="flex flex-col">
                                <div className="p-2 flex space-x-2">
                                    <div className="flex justify-center">
                                        <img src="https://firebasestorage.googleapis.com/v0/b/imagenes-fc412.appspot.com/o/user.jfif?alt=media&token=2e7c59b5-a71c-4739-8260-bb88b37b08fa"
                                            alt="perfil-foto"
                                            className="w-[50px] h-auto rounded-full object-cover" />
                                    </div>
                                    <div>
                                        <span className="text-[20px] font-semibold"> {item.name} </span>
                                        <Stars score={item.rate} />
                                    </div>
                                    <div className="flex items-end text-[13px] text-gray-400">
                                        <span>{item.date}</span>
                                    </div>
                                </div>
                            </div>
                            <div className="p-5 ">
                                <ReadMore >{item.description}</ReadMore>
                            </div>
                            <div className="w-full flex items-center space-x-3 ml-3">
                                <div className="w-[25px] h-[25px] flex items-center">
                                    <img src={item.ima} alt="agni" className="w-full mx-auto"/>
                                </div>
                                <div className="flex flex-col items-center justify-start">
                                    <p>Posted On</p>
                                    <a className="text-blue-500 w-full" href={item.enlace}>{item.red}</a>
                                </div>
                            </div>
                        </article>
                    );
                })
            }
        </section>
    );
}

export default Faq;